import { MapContainer as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet';
import { Swiper, SwiperSlide, useSwiper} from 'swiper/react';
import { points } from '../utils/Consts.js';
import {Helmet} from "react-helmet";

const SwiperButtonNext = ({ children }) => {
    const swiper = useSwiper();
    return <div className='swiper-button-next contacts__swiper-button-next' onClick={() => swiper.slideNext()}>{children}</div>;
  };


const SwiperButtonPrev = ({ children }) => {
const swiper = useSwiper();
return <div className='swiper-button-prev contacts__swiper-button-prev' onClick={() => swiper.slidePrev()}>{children}</div>;
};

function Contacts() {

    return (
        <section className="contacts">

            <Helmet>
                <title>VeryFood.ru - Контакты.</title>
                <meta name="keywords" content="Контакты, кафе, юридическое лицо"/>
                <meta name="description" content="Контакты"/>
            </Helmet>

            <div className="contacts__container">
                <h1 className="contacts__title">КОНТАКТЫ</h1>

                <LeafletMap
                    center={[55.908119, 37.509152]}
                    zoom={11}
                    maxZoom={16}
                    attributionControl={true}
                    zoomControl={true}
                    doubleClickZoom={true}
                    scrollWheelZoom={true}
                    dragging={true}
                    animate={true}
                    easeLinearity={0.35}
                >
                    <TileLayer
                    url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
                    />
                    {points.map((point)=>{
                        return (
                            <Marker position={[point.x, point.y]} key={point.address}>
                                <Popup>
                                    {point.address}
                                </Popup>
                            </Marker>
                        )
                    })

                    }
                </LeafletMap>

                <h2 className="contacts__title">НАШИ КАФЕ</h2>

                <div className="contacts__place">
                    <div className="contacts__place-info">
                        <i className="fa fa-map-marker contacts__place-icon" aria-hidden="true"></i><b> Адрес:</b>
                        <p className='contacts__place-text'>141700, г. Долгопрудный, ул. Первомайская 5. Московский, физико-технический институт, 2-й этаж, столовая КПМ.</p>
                        <i className="fa fa-clock-o contacts__place-icon" aria-hidden="true"></i><b> Время работы:</b>
                        <p className='contacts__place-text'>c 10:00 до 21:40</p>
                        <i className="fa fa-phone contacts__place-icon" aria-hidden="true"></i><b> Телефон:</b>
                        <p className='contacts__place-text'>8 800 775-78-86, доб 51</p>
                    </div>

                    <div className='contacts__swaper'>
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            loop={true}
                        >
                            <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2487.jpg" alt='КАФЕ'></img></SwiperSlide>
                            <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2484.jpg" alt='КАФЕ'></img></SwiperSlide>
                            <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2482.jpg" alt='КАФЕ'></img></SwiperSlide>
                            <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2479.jpg" alt='КАФЕ'></img></SwiperSlide>
                            
                            <SwiperButtonNext></SwiperButtonNext>
                            <SwiperButtonPrev></SwiperButtonPrev>

                        </Swiper>
                    </div>
                </div>

                <div className="contacts__place">
                    <div className="contacts__place-info">
                        <i className="fa fa-map-marker contacts__place-icon" aria-hidden="true"></i><b> Адрес:</b>
                        <p className='contacts__place-text'>141701, г. ДолгопрудныйИнститутский переулок, 5. Московский, физико-технический институт, новый корпус, 2-й этаж</p>
                        <i className="fa fa-clock-o contacts__place-icon" aria-hidden="true"></i><b> Время работы:</b>
                        <p className='contacts__place-text'>c 10:00 до 17:00</p>
                        <i className="fa fa-phone contacts__place-icon" aria-hidden="true"></i><b> Телефон:</b>
                        <p className='contacts__place-text'>8 800 775-78-86</p>
                    </div>

                    <div className='contacts__swaper'>
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            loop={true}
                        >
                            <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2500.jpg" alt='КАФЕ'></img></SwiperSlide>
                            <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2496.jpg" alt='КАФЕ'></img></SwiperSlide>
                            <SwiperSlide><img className="contacts__swape-image" src="/images/points/12de8edbede971d541613612cfd967f5.jpg" alt='КАФЕ'></img></SwiperSlide>
                            <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2491.jpg" alt='КАФЕ'></img></SwiperSlide>
                            
                            <SwiperButtonNext></SwiperButtonNext>
                            <SwiperButtonPrev></SwiperButtonPrev>

                        </Swiper>
                    </div>
                </div>

                <div className="contacts__place">
                    <div className="contacts__place-info">
                        <i className="fa fa-map-marker contacts__place-icon" aria-hidden="true"></i><b> Адрес:</b>
                        <p className='contacts__place-text'>141700, г. Долгопрудный, ул. Институтский переулок, 9. Московский, физико-технический институт, главный корпус, 2-й этаж</p>
                        <i className="fa fa-clock-o contacts__place-icon" aria-hidden="true"></i><b> Время работы:</b>
                        <p className='contacts__place-text'>c 10:00 до 17:00</p>
                        <i className="fa fa-phone contacts__place-icon" aria-hidden="true"></i><b> Телефон:</b>
                        <p className='contacts__place-text'>8 800 775-78-86</p>
                    </div>

                    <div className='contacts__swaper'>
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            loop={true}
                        >
                            <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2505.jpg" alt='КАФЕ'></img></SwiperSlide>
                            <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2512.jpg" alt='КАФЕ'></img></SwiperSlide>
                            <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2514.jpg" alt='КАФЕ'></img></SwiperSlide>
                            <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2518.jpg" alt='КАФЕ'></img></SwiperSlide>
                            
                            <SwiperButtonNext></SwiperButtonNext>
                            <SwiperButtonPrev></SwiperButtonPrev>

                        </Swiper>
                    </div>
                </div>

                <div className="contacts__place">
                    <div className="contacts__place-info">
                        <i className="fa fa-map-marker contacts__place-icon" aria-hidden="true"></i><b> Адрес:</b>
                        <p className='contacts__place-text'>141701, г. Долгопрудный, Лихачевский проезд, дом 4/1. Кафе "Самобранка"</p>
                        <i className="fa fa-clock-o contacts__place-icon" aria-hidden="true"></i><b> Время работы:</b>
                        <p className='contacts__place-text'>c 10:00 до 17:00</p>
                        <i className="fa fa-phone contacts__place-icon" aria-hidden="true"></i><b> Телефон:</b>
                        <p className='contacts__place-text'>8 800 775-78-86, доб. 52</p>
                    </div>

                    <div className='contacts__swaper'>
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            loop={true}
                        >
                            <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2444.jpg" alt='КАФЕ'></img></SwiperSlide>
                            <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2446.jpg" alt='КАФЕ'></img></SwiperSlide>
                            <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2449.jpg" alt='КАФЕ'></img></SwiperSlide>
                            <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2447.jpg" alt='КАФЕ'></img></SwiperSlide>
                            
                            <SwiperButtonNext></SwiperButtonNext>
                            <SwiperButtonPrev></SwiperButtonPrev>

                        </Swiper>
                    </div>
                </div>

                <div className="contacts__place">
                    <div className="contacts__place-info">
                        <i className="fa fa-map-marker contacts__place-icon" aria-hidden="true"></i><b> Адрес:</b>
                        <p className='contacts__place-text'>141700, г. Долгопрудный, проспект Пацаева, дом 7.</p>
                        <i className="fa fa-clock-o contacts__place-icon" aria-hidden="true"></i><b> Время работы:</b>
                        <p className='contacts__place-text'>c 10:00 до 17:00</p>
                        <i className="fa fa-phone contacts__place-icon" aria-hidden="true"></i><b> Телефон:</b>
                        <p className='contacts__place-text'>8 800 775-78-86</p>
                    </div>

                    <div className='contacts__swaper'>
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            loop={true}
                        >
                            <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2520.jpg" alt='КАФЕ'></img></SwiperSlide>
                            <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2521.jpg" alt='КАФЕ'></img></SwiperSlide>
                            <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2528.jpg" alt='КАФЕ'></img></SwiperSlide>
                            <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2531.jpg" alt='КАФЕ'></img></SwiperSlide>
                            
                            <SwiperButtonNext></SwiperButtonNext>
                            <SwiperButtonPrev></SwiperButtonPrev>

                        </Swiper>
                    </div>
                </div>

                <h2 className="contacts__title">ИНФОРМАЦИЯ О ЮРИДИЧЕСКОМ ЛИЦЕ</h2>

                <table className='contacts__info'>
                    <tbody>
                        <tr className='contacts__info-tr'>
                            <td className='contacts__info-td'>
                                <p>Название компании</p>
                            </td>
                            <td className='contacts__info-td'>АНО "ЗДОРОВОЕ ПИТАНИЕ"</td>
                        </tr>
                        <tr className='contacts__info-tr'>
                            <td className='contacts__info-td'>
                                <p>ИНН</p>
                            </td>
                            <td className='contacts__info-td'>5047193519</td>
                        </tr>
                        <tr className='contacts__info-tr'>
                            <td className='contacts__info-td'>
                                <p>Режим работы</p>
                            </td>
                            <td className='contacts__info-td'>Пн-Вс с 08:00 до 21:40</td>
                        </tr>
                        <tr className='contacts__info-tr'>
                            <td className='contacts__info-td'>
                                <p>Юридический адрес</p>
                            </td>
                            <td className='contacts__info-td'>141720, Московская обл., г Долгопрудный, ул Комсомольская (Хлебниково Мкр), 5</td>
                        </tr>
                        <tr className='contacts__info-tr'>
                            <td className='contacts__info-td'>
                                <p>Физический адрес</p>
                            </td>
                            <td className='contacts__info-td'>141720, Московская обл., г Долгопрудный, ул Комсомольская (Хлебниково Мкр), 5</td>
                        </tr>
                        <tr className='contacts__info-tr'>
                            <td className='contacts__info-td'>
                                <p>E-mail</p>
                            </td>
                            <td className='contacts__info-td'>info@veryfood.ru</td>
                        </tr>
                    </tbody>
                </table>
                

            </div>
        </section>
    );
}
    
export default Contacts;