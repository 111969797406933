import Food from "./components/food.js";
import PlaceSelect from './components/PlaceSelect';
import LkPopup from './components/LkPopup.js'
import ShippinAndPayment from './components/ShippinAndPayment.js'
import About from "./components/About.js";
import Contacts from "./components/Contacts.js";
import Corporatives from "./components/Corporatives.js";
import ReviewForm from './components/ReviewForm';
import Tracking from "./components/Tracking.js";

import React from 'react';
import { Routes, Route, Navigate} from 'react-router-dom';
import {getCookie} from './utils/Consts.js'
import { getUserInfo, signOut, getInfo} from "./utils/Api.js";
import { NavLink } from 'react-router-dom';
import { Swiper, SwiperSlide, useSwiper} from 'swiper/react';

import logo from './images/logo.png'

const isPrerender = navigator.userAgent === "ReactSnap"

const SwiperButtonNext = () => {
  const swiper = useSwiper();
  return <div className='swiper-button-next arrow-review-next' onClick={() => swiper.slideNext()}></div>;
};


const SwiperButtonPrev = () => {
const swiper = useSwiper();
return <div className='swiper-button-prev arrow-review-prev' onClick={() => swiper.slidePrev()}></div>;
};

function App() {

  const [isRegister, setIsRegister] = React.useState(false);
  const [isLkOpened, setIsLkOpened] = React.useState(false);
  const [isMenuOpened, setIsMenuOpened] = React.useState(false);
  const [token, setToken] = React.useState(window.localStorage.getItem('token'));
	const [user, setUser] = React.useState(null);
  const [info, setInfo] = React.useState('');

  const handleSignOut = () => {
    signOut(user?.access_token)
    .then(()=>{
      setUser(null);
		  setToken(null);
      window.localStorage.removeItem('publicUser');
		  window.localStorage.removeItem('token');
    })
    .catch((e)=>{
      console.error(e);
    })
	}

  React.useEffect(() => {
    getUserInfo(token)
    .then((data)=>{
      if (data.data && JSON.stringify(data.data) !== JSON.stringify(user))
			setUser(data.data);
    })
    .then(()=>{
      window.localStorage.setItem('publicUser', JSON.stringify(user));
		  window.localStorage.setItem('token', token);
    })
    .catch ((e)=>{
      console.error(e);
    })

	}, [user, token]);

  React.useEffect(() => {

    getInfo()
    .then((data)=>{
      if(data.error){
        console.log(data.error_description);
        setInfo('default')
      } else {
        setInfo(data.data)
      }
    })
    .catch((e)=>{
      console.log(e);
    })

  }, []);

  return (
    <>

    <header className="header">
      <div className="header__presentation" id="header">
        <div className="header__logo-container">
          <img src={logo} className="header__logo" alt="Логотип VeryFood"/>
        </div>
        <div className="header__orders">
          <h2 className="header__orders-title">ЗАКАЗОВ СЕГОДНЯ:</h2>
          { info 
          ?
          <div className="header__counter">      
            <div className="header__counter-container">
              <div className="header__counter-digit">{info === 'default' || !info.order_counter ? 1 : String(info.order_counter).length > 2 ? String(info.order_counter).substring(0, String(info.order_counter).length - 2) : 0 }</div>
            </div>
            <div className="header__counter-container">
              <div className="header__counter-digit">{info === 'default' || !info.order_counter ? 2 : String(info.order_counter).length > 1 ? String(info.order_counter).substring(String(info.order_counter).length - 2, String(info.order_counter).length - 1) : 0 }</div>
            </div>
            <div className="header__counter-container">
              <div className="header__counter-digit">{info === 'default' || !info.order_counter ? 3 : String(info.order_counter).length > 1 ? String(info.order_counter).substring(String(info.order_counter).length - 1) : String(info.order_counter) }</div>
            </div>
          </div>
          :
          <div className="header__counter">
            <i className="fa fa-refresh fa-spin fa-3x fa-fw"></i>
          </div>
          }
        </div>
        <div className="header__info">
          <h2 className="header__info-title">ЗВОНОК БЕСПЛАТНЫЙ</h2>
          <a className="header__info-phone" href="tel:8 (800) 775 78 86">8-800-775-78-86</a>
          <h3 className="header__info-interval">Доставка еды: <span className="header__info-interval-span">с {info ? info === 'default' || !info.worktime_from ? '10:00' : info.worktime_from.substring(0,5) : '...'} - {info ? info === 'default' || !info.worktime_to ? '18:00' : info.worktime_to.substring(0,5) : '...'}</span></h3>
          <div className="header__time-box">
            <div className="header__time-box-left">
                Среднее время доставки сегодня:
            </div>
            <div className="header__time-box-right">
                <i className="fa fa-clock-o fa-2x header__time-box-image" aria-hidden="true"></i>
                <p className="header__time-box-minutes">{info ? info === 'default' || !info.avarage_time || info.avarage_time < 45 ? '45' : info.avarage_time : '...'}</p>
                <p className="header__time-box-unit">мин</p>
            </div>                        
          </div>
          <button className="header__info-menu" onClick={()=>{setIsMenuOpened(true)}}>
            <i className="fa fa-bars fa-3x header__info-menu-image" aria-hidden="true"></i>
          </button>
        </div>
      </div>

    </header>

    
    <main className="App">

      <div className="header__nav">
        <div className="header__nav-bar">
          
          <div className="header__nav-place">
            <PlaceSelect />
          </div>

          <div className="header__nav-menu">
            <NavLink to="/" className={({isActive}) => `${isActive ? "header__nav-menu-link header__nav-menu-link_active" : "header__nav-menu-link"}`}>Меню</NavLink>
            <NavLink to="/tracking" className={({isActive}) => `${isActive ? "header__nav-menu-link header__nav-menu-link_active" : "header__nav-menu-link"}`}>Статус заказа</NavLink>
            <NavLink to="/shipping_and_payment" className={({isActive}) => `${isActive ? "header__nav-menu-link header__nav-menu-link_active" : "header__nav-menu-link"}`}>Доставка и оплата</NavLink>
            <NavLink to="/about" className={({isActive}) => `${isActive ? "header__nav-menu-link header__nav-menu-link_active" : "header__nav-menu-link"}`}>О нас</NavLink>
            <NavLink to="/contacts" className={({isActive}) => `${isActive ? "header__nav-menu-link header__nav-menu-link_active" : "header__nav-menu-link"}`}>Контакты</NavLink>
            <NavLink to="/corporatives" className={({isActive}) => `${isActive ? "header__nav-menu-link header__nav-menu-link_active" : "header__nav-menu-link"}`}>Корпоративы</NavLink>
          </div>
          {user
          ?
            <div className="header__nav-lk-enter">
              <p className="header__nav-lk-enter-text" onClick={()=>{setIsLkOpened(true)}}>{user.name}/{user.balance}р.</p>
					    <i className="fa fa-sign-out fa-lg header__nav-place-image" onClick={handleSignOut}/>
            </div>
          :
            <div className="header__nav-lk-enter" onClick={()=>{setIsRegister(true)}}>
              <i className="fa fa-sign-in fa-lg header__nav-place-image" aria-hidden="true"></i>
              <p className="header__nav-lk-enter-text">Вход/Регистрация</p>
            </div>
          }
        </div>
      </div>

      <div className={getCookie('place_id') && !isMenuOpened ? 'menu' : 'menu menu_opened'} onClick={(e)=>{if (e.target === document.getElementsByClassName('menu_opened')[0]){setIsMenuOpened(false)}}}>
        <div className="menu__container">
          <img src={logo} className="menu__logo" alt="VeryFood"/>
          <div className="menu__buttons">
            <div className="menu__button-block">
              <PlaceSelect />
            </div>
            {user
            ?
              <div className="menu__button-block">
                <p className="menu__button-text" onClick={()=>{setIsLkOpened(true)}}>{user.name}/{user.balance}р.</p>
                <i className="fa fa-sign-out fa-lg menu__button-image" onClick={handleSignOut}/>
              </div>
            :
              <div className="menu__button-block" onClick={()=>{setIsRegister(true)}}>
                <i className="fa fa-sign-in fa-lg menu__button-image" aria-hidden="true"></i>
                <p className="menu__button-text">Вход/Регистрация</p>
              </div>
            }
          </div>

          <NavLink to="/" className={({isActive}) => `${isActive ? "menu__link menu__link_active" : "menu__link"}`} onClick={()=>{setIsMenuOpened(false)}}>Меню</NavLink>
          <NavLink to="/tracking" className={({isActive}) => `${isActive ? "menu__link menu__link_active" : "menu__link"}`} onClick={()=>{setIsMenuOpened(false)}}>Статус заказа</NavLink>
          <NavLink to="/shipping_and_payment" className={({isActive}) => `${isActive ? "menu__link menu__link_active" : "menu__link"}`} onClick={()=>{setIsMenuOpened(false)}}>Доставка и оплата</NavLink>
          <NavLink to="/about" className={({isActive}) => `${isActive ? "menu__link menu__link_active" : "menu__link"}`} onClick={()=>{setIsMenuOpened(false)}}>О нас</NavLink>
          <NavLink to="/contacts" className={({isActive}) => `${isActive ? "menu__link menu__link_active" : "menu__link"}`} onClick={()=>{setIsMenuOpened(false)}}>Контакты</NavLink>
          <NavLink to="/corporatives" className={({isActive}) => `${isActive ? "menu__link menu__link_active" : "menu__link"}`} onClick={()=>{setIsMenuOpened(false)}}>Корпоративы</NavLink>

          <h2 className="menu__info-title">ЗВОНОК БЕСПЛАТНЫЙ</h2>
          <a className="menu__info-phone" href="tel:8 (800) 775 78 86">8-800-775-78-86</a>
          <h3 className="menu__info-interval">Доставка еды: <span className="menu__info-interval-span">с {info ? info === 'default' || !info.worktime_from ? '10:00' : info.worktime_from.substring(0,5) : '...'} - {info ? info === 'default' || !info.worktime_to ? '18:00' : info.worktime_to.substring(0,5) : '...'}</span></h3>
          <div className="menu__time-box">
            <div className="menu__time-box-left">
                Среднее время доставки сегодня:
            </div>
            <div className="menu__time-box-right">
                <i className="fa fa-clock-o fa-2x menu__time-box-image" aria-hidden="true"></i>
                <p className="menu__time-box-minutes">{info ? info === 'default' || !info.avarage_time ? '30' : info.avarage_time : '...'}</p>
                <p className="menu__time-box-unit">мин</p>
            </div>                        
          </div>
          <i className="fa fa-times menu__close" aria-hidden="true" onClick={()=>{setIsMenuOpened(false)}}></i>
        </div>
      </div>

      <Routes>
        <Route path="/" element={<Food user={user} setUser={setUser} info={info}/>} />
        <Route path="/tracking" element={<Tracking/>} /> 
        <Route path="/shipping_and_payment" element={<ShippinAndPayment />} /> 
        <Route path="/about" element={<About />} /> 
        <Route path="/contacts" element={<Contacts />} /> 
        <Route path="/corporatives" element={<Corporatives/>} />
        <Route path="*" element={<div>{ isPrerender ? <h1>404</h1> : <Navigate to="/" />}</div>} />
      </Routes>

      <LkPopup isRegister={isRegister} setIsRegister={setIsRegister} user={user} setUser={setUser} setToken={setToken} isLkOpened= {isLkOpened} setIsLkOpened={setIsLkOpened}/>
    </main>

    <footer className="footer">
        <div className="footer__reviews">

          <div className="footer__reviews-recent swiper-review">
            <h2 className="footer__swiper-title">ЧИТАЙ ПОСЛЕДНИЕ ОТЗЫВЫ О НАС!</h2>

            {info
            ?
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              loop={true}
            >
              { info !== 'default'
              ?
                info.reviews.map((rew, index) => {
                  return (
                    <SwiperSlide>
                    <div className="review">
                        <i className="fa fa-quote-left review-image" aria-hidden="true"></i>
                        {rew.text}
                        <span className="review__span">{rew.name}, {index > 3 ? "Позавчера" : index > 1 ? "Вчера" : "Сегодня"}</span>
                    </div>
                    </SwiperSlide>
                  );
              })
              :
              <SwiperSlide>
              <div className="review">
                  {"Отзывов пока нет."}
                </div>
              </SwiperSlide>
              }

              <SwiperButtonNext></SwiperButtonNext>
              <SwiperButtonPrev></SwiperButtonPrev>

          </Swiper>
          : 
          <div className="footer__preloader">
            <i className="fa fa-refresh fa-spin fa-3x fa-fw"></i>
          </div>
          }
          </div>
            
          <div className="footer__form-container">
            <ReviewForm />
          </div>
          
      </div>
  </footer>

    </>
  );
}

export default App;
