import React from 'react';
import { Swiper, SwiperSlide, useSwiper} from 'swiper/react';

const SwiperButtonNext = () => {
    const swiper = useSwiper();
    return <div className='swiper-button-next arrow-review-next' onClick={() => swiper.slideNext()}></div>;
  };
  
  
  const SwiperButtonPrev = () => {
  const swiper = useSwiper();
  return <div className='swiper-button-prev arrow-review-prev' onClick={() => swiper.slidePrev()}></div>;
  };

function ComplexPopup(props) {

    const [index, setIndex] = React.useState(1);
    const [combo, setCombo] = React.useState({1:null,2:null,3:null,4:null});

    function order() {

        let complex = JSON.parse(JSON.stringify(props.products.find((prod)=>{return prod.id === 3066})))
        complex.title = complex.title + ": " + combo[1].title + " + " + combo[2].title + " + " + combo[3].title + " + " + combo[4].title
        let c1 = JSON.parse(JSON.stringify(combo[1]))
        c1.price.value = 0
        c1.complex_id = 1
        c1.multiplier = 0
        let c2 = JSON.parse(JSON.stringify(combo[2]))
        c2.price.value = 0
        c2.complex_id = 1
        c2.multiplier = 0
        let c3 = JSON.parse(JSON.stringify(combo[3]))
        c3.price.value = 0
        c3.complex_id = 1
        c3.multiplier = 0
        let c4 = JSON.parse(JSON.stringify(combo[4]))
        c4.price.value = 0
        c4.complex_id = 1
        c4.multiplier = 0
        let c5 = JSON.parse(JSON.stringify(props.products.find((prod)=>{return prod.id === 36})))
        c5.price.value = 0
        c5.complex_id = 1
        c5.multiplier = 0
        let c6 = JSON.parse(JSON.stringify(props.products.find((prod)=>{return prod.id === 37})))
        c6.price.value = 0
        c6.complex_id = 1
        c6.multiplier = 0


        props.setBasket([...props.basket, 
            {
                item: complex,
                x: 1,
            },
            {
                item: c1,
                x: 1,
            },
            {
                item: c2,
                x: 1,
            },
            {
                item: c3,
                x: 1,
            },
            {
                item: c4,
                x: 1,
            },
            {
                item: c5,
                x: 1,
            },
            {
                item: c6,
                x: 1,
            },
        ])

        if(props.orderID){
            document.cookie = `order_id=;expires=${new Date(0)}`
            props.setOrderID('')
            window.localStorage.removeItem('paykeeper');
            window.localStorage.removeItem('prettyCart');
            window.localStorage.removeItem('createbinding');
        }
        setIndex(1)
        setCombo({1:null,2:null,3:null,4:null})
        props.setIsComplex(false)
    }
 
    return (
        <div 
        className={props.isComplex ? 'popup popup_opened' : 'popup'} 
        onClick={(e)=>{if (e.target === document.getElementsByClassName('popup_opened')[0]) {props.setIsComplex(false)}}}
        >   
             
            <div className="popup__complex">

                <h2 className='popup__complex-title'>Веберите {index === 1 ? "Первое" : index === 2 ? "Второе" : index === 3 ? "Гарнир" : "Салат"}</h2>
                
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                >
                    
                    {props.products.filter((p) => { return p.sections_id === index}).map((item) => {
                        
                        return (<>
                            {item.compex_quantity && item.compex_quantity.value !== 0 ? 
                        <SwiperSlide>
                        <div className='popup__complex-item'>
                            <img src={`images/products/preview/${item.picture}`} alt={item.title} className="popup__complex-image"></img>
                            <h2 className="popup__complex-name">{item.title}</h2>
                            {combo[index]?.id === item.id
                            ?
                            <div className='popup__complex-picked'>Выбрано</div>
                            :
                            <button className='popup__complex-button' onClick={()=>{setCombo({...combo, [index] : item}); if(index !== 4) {setIndex(index + 1)}}}>Выбрать</button>
                            }
                        </div>
                        </SwiperSlide>
                        :
                        null
                        }
                        </>);
                    }
                    )}
                    
                    <SwiperButtonNext></SwiperButtonNext>
                    <SwiperButtonPrev></SwiperButtonPrev>

                </Swiper>
                {/* <div className='popup__complex-buttons'>
                    <button className='popup__complex-button'onClick={()=>{setIndex(index - 1)}} disabled={index === 1}>Назад</button>
                    {combo[1] && combo[2] && combo[3] && combo[4]
                    ? <button className='popup__complex-button'onClick={()=>{setIndex(5)}}>К составу</button>
                    : null
                    }
                    <button className='popup__complex-button' onClick={()=>{setIndex(index + 1)}} disabled={!combo[index]}>{index === 1 ? 'К выбору второго' : index === 2 ? 'К выбору гарнира' : 'К выбору салаты'}</button>
                </div> */}

                {/* <h2 className='popup__complex-title'>Состав обеда:</h2> */}
                <div className='popup__complex-combo'>
                    <h3 className="popup__complex-name">Первое: {combo[1]?.title ?? "Не выбрано"}</h3>
                    <button className='popup__complex-button' onClick={()=>{setIndex(1)}}>{combo[1] ? "Заменить" : "К выбору"}</button>
                </div>
                <div className='popup__complex-combo'>
                    <h3 className="popup__complex-name">Второе: {combo[2]?.title ?? "Не выбрано"}</h3>
                    <button className='popup__complex-button' onClick={()=>{setIndex(2)}}>{combo[2] ? "Заменить" : "К выбору"}</button>
                </div>
                <div className='popup__complex-combo'>
                    <h3 className="popup__complex-name">Гарнир: {combo[3]?.title ?? "Не выбрано"}</h3>
                    <button className='popup__complex-button' onClick={()=>{setIndex(3)}}>{combo[3] ? "Заменить" : "К выбору"}</button>
                </div>
                <div className='popup__complex-combo'>
                    <h3 className="popup__complex-name">Салат: {combo[4]?.title ?? "Не выбрано"}</h3>
                    <button className='popup__complex-button' onClick={()=>{setIndex(4)}}>{combo[4] ? "Заменить" : "К выбору"}</button>
                </div>
                <h3 className="popup__complex-name">Хлеб - 2 шт.</h3>

                <div className='popup__complex-buttons'>
                    {/* <button className='popup__complex-button'onClick={()=>{setIndex(index - 1)}} disabled={index === 1}>Назад</button> */}
                    <p className="popup__complex-price">
                        <span className="popup__complex-price-span" onClick={()=>{console.log(combo);}}>Цена: </span>
                        300 руб.    
                    </p>
                    <button className='popup__complex-button' disabled={!combo[1] || !combo[2] || !combo[3] || !combo[4]} onClick={order}>Заказать</button>
                </div>

                <i className="fa fa-times popup__close" aria-hidden="true" onClick={()=>{props.setIsComplex(false)}}></i>

            </div>
            
            {/* <div className="popup__complex"> */}
                {/* <h2 className='popup__complex-title'>Состав обеда:</h2>
                <div className='popup__complex-combo'>
                    <h3 className="popup__complex-name">Первое: {combo[1]?.title}</h3>
                    <button className='popup__complex-button' onClick={()=>{setIndex(1)}}>Заменить</button>
                </div>
                <div className='popup__complex-combo'>
                    <h3 className="popup__complex-name">Второе: {combo[2]?.title}</h3>
                    <button className='popup__complex-button' onClick={()=>{setIndex(2)}}>Заменить</button>
                </div>
                <div className='popup__complex-combo'>
                    <h3 className="popup__complex-name">Гарнир: {combo[3]?.title}</h3>
                    <button className='popup__complex-button' onClick={()=>{setIndex(3)}}>Заменить</button>
                </div>
                <div className='popup__complex-combo'>
                    <h3 className="popup__complex-name">Салат: {combo[4]?.title}</h3>
                    <button className='popup__complex-button' onClick={()=>{setIndex(4)}}>Заменить</button>
                </div>
                <h3 className="popup__complex-name">Хлеб - 2 шт.</h3>

                <div className='popup__complex-buttons'>
                    <button className='popup__complex-button'onClick={()=>{setIndex(index - 1)}} disabled={index === 1}>Назад</button>
                    <p className="popup__complex-price">
                        <span className="popup__complex-price-span">Цена: </span>
                        300 руб.    
                    </p>
                    <button className='popup__complex-button' onClick={order}>Заказать</button>
                </div>

                <i className="fa fa-times popup__close" aria-hidden="true" onClick={()=>{props.setIsComplex(false)}}></i> */}
            {/* </div> */}
            

        </div>
    );
}
    
export default ComplexPopup;