import clock from "../images/shipping_clock.png"
import fast from "../images/shipping_fast_delivery.png"
import rub from "../images/shipping_rub.png"
import visa from "../images/visa.png"
import mastercard from "../images/mastercard.png"
import amex from "../images/amex.png"
import jcb from "../images/jcb.png"
import dci from "../images/dci.png"
import {Helmet} from "react-helmet";

function ShippinAndPayment() {

    return (
        <section className="shippin-and-payment">

            <Helmet>
                <title>VeryFood.ru - Доставка и оплата.</title>
                <meta name="keywords" content="Доставка, оплата, банковская карта, платежные системы, безопастность, возврат"/>
                <meta name="description" content="Доставка и оплата"/>
            </Helmet>

            <div className="shippin-and-payment__container">
                <h1 className="shippin-and-payment__title">ДОСТАВКА И ОПЛАТА</h1>
                <div className="shippin-and-payment__info-container">
                    <div className="shippin-and-payment__info-block">
                        <img className="shippin-and-payment__info-image" alt="" src={clock}></img>
                        <h3 className="shippin-and-payment__info-block-title">ВРЕМЯ РАБОТЫ</h3>
                        <p className="shippin-and-payment__info-block-text">
                            Наша доставка еды по Подмосковью работает ежедневно с 10:00 до 21:40. Мы готовы принять ваш заказ
                            и оформить доставку еды в офис, квартиру, общежитие или университет.
                        </p>
                    </div>
                    <div className="shippin-and-payment__info-block">
                        <img className="shippin-and-payment__img" alt="" src={fast}></img>
                        <h3 className="shippin-and-payment__info-block-title">ДОСТАВКА</h3>
                        <p className="shippin-and-payment__info-block-text"> 
                        Примерное время доставки еды составляет 30 минут. Время доставки еды может меняться в зависимости 
                        от вашего местонахождения, загрузки обслуживающего персонала.
                        </p>
                    </div>
                    <div className="shippin-and-payment__info-block">
                        <img className="shippin-and-payment__img" alt="" src={rub}></img>
                        <h3 className="shippin-and-payment__info-block-title">ОПЛАТА</h3>
                        <p className="shippin-and-payment__info-block-text">
                            Вы можете оплатить ваш заказ при получении наличными курьеру в руки, 
                            либо Оплатить заказ банковской картой у нас на сайте в момент оформления заказа.
                        </p>
                    </div>
                </div>

                <h2 className="shippin-and-payment__title">ОПЛАТА БАНКОВСКОЙ КАРТОЙ</h2>
                <p className="shippin-and-payment__text">
                    Оплатить ваш заказ вы можете посредством пластиковой карты. Оплата зачисляется в течение нескольких минут с момента совершения операции в платёжных системах. Предоставляемая вами персональная информация (имя, адрес, телефон, e-mail, номер кредитной карты) является конфиденциальной и не подлежит разглашению. Данные вашей кредитной карты передаются только в зашифрованном виде и не сохраняются на нашем Web-сервере.<br></br><br></br>

                    Безопасность обработки Интернет-платежей по пластиковым картам гарантирует банк-эквайер. Все операции с картами происходят в соответствии с требованиями VISA International, MasterCard и других платежных систем. При передаче информации используются специальные технологии безопасности карточных онлайн-платежей, обработка данных ведется на безопасном высокотехнологичном процессинговом сервере.<br></br><br></br>

                    В случае возникновения вопросов по поводу конфиденциальности операций с платёжными картами и предоставляемой вами информации вы можете связаться со службой технической поддержки банка.<br></br><br></br>

                    На странице авторизации потребуется ввести номер карты, имя владельца карты, срок действия карты, верификационный номер карты (CVV2 для VISA или CVC2 для MasterCard). Все необходимые данные пропечатаны на самой карте. Верификационный номер карты — это три цифры, находящиеся на обратной стороне карты.<br></br><br></br>

                    Для оплаты вы будете перенаправлены на страницу банка.<br></br><br></br>

                    Произвести оплату необходимо в течение 15 минут после перехода на страницу авторизации карточки.<br></br><br></br>

                    Транзакция может занять около 40 секунд. Дождитесь завершения операции. Не нажимайте повторно кнопку «Заплатить».<br></br><br></br>

                    Платеж происходит в режиме реального времени и зачисляется в течение 15 минут.<br></br><br></br>
                </p>

                <h3 className="shippin-and-payment__title">Платежные системы</h3>
                <div className="shippin-and-payment__images">
                    <div className="shippin-and-payment__image-block">
                        <img className="shippin-and-payment__image" alt="visa" src={visa}></img>
                    </div>
                    <div className="shippin-and-payment__image-block">
                        <img className="shippin-and-payment__image" alt="mastercard" src={mastercard}></img>
                    </div>
                    <div className="shippin-and-payment__image-block">
                        <img className="shippin-and-payment__image" alt="amex" src={amex}></img>
                    </div>
                    <div className="shippin-and-payment__image-block">
                        <img className="shippin-and-payment__image" alt="jcb" src={jcb}></img>
                    </div>
                    <div className="shippin-and-payment__image-block">
                        <img className="shippin-and-payment__image" alt="dci" src={dci}></img>
                    </div>
                </div>

                <h2 className="shippin-and-payment__title">БЕЗОПАСНОСТЬ ПЛАТЕЖЕЙ</h2>
                <p className="shippin-and-payment__text">
                    Предоставляемая Вами персональная информация (имя, адрес, телефон, e-mail, номер кредитной карты) является конфиденциальной и не подлежит разглашению. Данные Вашей кредитной карты передаются только в зашифрованном виде и не сохраняются на нашем Web-сервере.<br></br><br></br>

                    Безопасность обработки Интернет-платежей гарантирует ЗАО «Банк Русский Cтандарт». Все операции с платежными картами происходят в соответствии с требованиями VISA International, MasterCard, American Express и других платежных систем. При передаче информации используются специальные технологии безопасности карточных онлайн-платежей, обработка данных ведется на безопасном высокотехнологичном сервере процессинговой компании. В случаи использования регулярных платежей, при совершении первой операции номер Вашей карты и срок действия будут сохранены на стороне Банка в безопасном хранилище. По факту выполнения или оказания услуг со стороны компании, данные будут удалены автоматически. Все ресурсы Банка соответствуют стандартам безопасности PCI DSS.<br></br><br></br>

                    При проведении операции, Вы будете дополнительно перенаправлены на страницу Банка-Эмитента для ввода подтверждающего кода безопасности (в случае если банк, выпустивший Вашу карту, поддерживает технологию 3DSecure, и Ваша карта также поддерживает данную программу).<br></br><br></br>

                    3D Secure – процедура дополнительной аутентификации держателя карты, предназначенная для обеспечения гарантированной безопасности платежей по банковским картам в Сети Интернет.<br></br><br></br>

                    Технологическое решение 3D Secure имеет свой собственный бренд в каждой из платежных систем: - Verified by Visa – по картам платежной системы Visa Int. - MasterCard Secure Code – по картам, выпущенным в рамках MasterCard Int - SafeKey и JSecure – платежная система American Express и JCB соответственно.<br></br><br></br>

                    В независимости от названия, технология 3D Secure предполагает защищенную сессию при проведении интернет-транзакции и позволяет пользователям-держателям карты, подтвердить правомерность операции для Банка выпустивший карту.<br></br><br></br>

                    В случае возникновения вопросов по поводу данной конфиденциальности предоставляемой Вами информации, Вы можете связаться с ЗАО «Банк Русский Cтандарт» по телефону: 8 (800) 200-6-200.<br></br><br></br>
                </p>

                <h3 className="shippin-and-payment__title">Возврат товара</h3>
                <p className="shippin-and-payment__text"></p>
                <ul>
                    <li>
                        <p className="shippin-and-payment__text">Потребитель вправе отказаться от товара в любое время до его передачи, а после передачи товара - в течение семи дней;</p>
                    </li>
                    <li>
                        <p className="shippin-and-payment__text">Возврат товара надлежащего качества возможен в случае, если сохранены его товарный вид, потребительские свойства, а также документ, подтверждающий факт и условия покупки указанного товара;</p>
                    </li>
                    <li>
                        <p className="shippin-and-payment__text">Потребитель не вправе отказаться от товара надлежащего качества, имеющего индивидуально-определенные свойства, если указанный товар может быть использован исключительно приобретающим его человеком;</p>
                    </li>
                    <li>
                        <p className="shippin-and-payment__text">При отказе потребителя от товара продавец должен возвратить ему денежную сумму, уплаченную потребителем по договору, за исключением расходов продавца на доставку от потребителя возвращенного товара, не позднее чем через десять дней со дня предъявления потребителем соответствующего требования;</p>
                    </li>
                </ul>
        
            </div>
        </section>
    );
}
    
export default ShippinAndPayment;