import React, { useState } from 'react'
import { PLACES } from '../utils/Consts';
import { sendReview } from '../utils/Api';
import payments from '../images/payments.png'

export default function ReviewForm() {
    const [ values, setValues ] = useState({
        name: '',
        order_id: '',
        place: PLACES.find(p => p.id === parseInt(window.localStorage.getItem('place_id')))?.title,
        email: '',
        text: '',
        was_send: false,
    });

    const [ need_check_errors, setErrorCheck ] = useState(false);
    const [ isSending, setIsSending ] = useState(false);

    const handleInputChange = name => event => {
        setValues({...values, [name]: event.target.value});
    }

    const handleSend = async (e) => {
        e.preventDefault();
        setIsSending(true);
        setErrorCheck(true);
        if (!(values?.name && values?.text.trim())) {
            setIsSending(false);
            return;
        }
        try {
            await sendReview({...values, ['text']: values?.text.trim()});
        } catch (e) {
            console.error(e);
            setIsSending(false);
            return;
        }
        setIsSending(false);
        setValues({...values, ['was_send']: true});
    }

    return (
    <form className="footer__form" onSubmit={(e)=>{handleSend(e)}}>
        <h2 className="footer__form-title">ОСТАВЛЯЙ СВОЙ ОТЗЫВ!</h2> 
        <input type="text" className={need_check_errors && !values?.name ? 'footer__form-input footer__form-input_error' : 'footer__form-input'} placeholder="Имя*" disabled={values?.was_send} value={values?.name} onChange={handleInputChange('name')}></input>
        <input type="text" className="footer__form-input" placeholder="Номер заказа" disabled={values?.was_send} value={values?.order_id} onChange={handleInputChange('order_id')}></input>
        <select className="footer__form-input footer__form-input_select" disabled={values?.was_send} defaultValue={values?.place} onChange={handleInputChange('place')}>
              {PLACES.map(p => <option key={p.id} value={p.title}>{p.title}</option>)}
        </select>
        <input type="text" className="footer__form-input" placeholder="e-mail" disabled={values?.was_send} value={values?.email} onChange={handleInputChange('email')}></input>
        <textarea className={need_check_errors && !(values?.text.trim()) ? 'footer__form-textarea footer__form-textarea_error' : 'footer__form-textarea'} placeholder="Ваш отзыв*" disabled={values?.was_send} value={values?.text} onChange={handleInputChange('text')}></textarea>
        <button type="submit" className="footer__form-submit" disabled={values?.was_send}>{isSending ? 'Отправка...': values?.was_send ? "Отправлено!" : "Отправить"}</button>
        <img src={payments} className="footer__form-image" alt="Мы работаем с VISA и Master Card используя платежную платформу PayKeeper"></img>
    </form>
    );
}