import { Swiper, SwiperSlide, useSwiper} from 'swiper/react';
import {Helmet} from "react-helmet";

const SwiperButtonNext = ({ children }) => {
    const swiper = useSwiper();
    return <div className='swiper-button-next contacts__swiper-button-next' onClick={() => swiper.slideNext()}>{children}</div>;
  };


const SwiperButtonPrev = ({ children }) => {
const swiper = useSwiper();
return <div className='swiper-button-prev contacts__swiper-button-prev' onClick={() => swiper.slidePrev()}>{children}</div>;
};

function Corporatives() {

    return (
        <section className="corporatives">

            <Helmet>
                <title>VeryFood.ru - Корпоративы.</title>
                <meta name="keywords" content="Корпоративы, корпоративное питание, фото"/>
                <meta name="description" content="Корпоративы"/>
            </Helmet>

            <div className="corporatives__container">
                <h1 className="corporatives__title">КОРПОРАТИВЫ И КОРПОРАТИВНОЕ ПИТАНИЕ</h1>
                <p className="corporatives__text">
                    VeryFood предлагает услугу проведения корпоративов. Выездная кухня из профессиональных поваров и организаторов обеспечит ваше мероприятие целым 
                    набором блюд в корпоративной сервировке. Мы всегда готовы помочь вам провести корпоратив, праздник или похороны, предоставив все необходимое для приема пищи. 
                    Благодаря опытной команде мы еженедельно предоставляем качественные услуги выездной кухни.
                    Кроме того, мы можем работать с вами на долгосрочной перспективе, предоставляя сотрудникам вашей компании корпоративное питание. 
                    Специальный функционал сайта для корпоративных пользователей позволит сделать наше взаимодействие максимально комфортным и эффективным.
                    По всем вопросам пишите на почту или звоните по телефону. Наши контакты представлены на сайте в разделе "контакты".
                </p>

                <h2 className="corporatives__title">НАШИ ФОТО</h2>

                <div className='corporatives__swaper'>
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        loop={true}
                    >
                        <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2487.jpg" alt='КАФЕ'></img></SwiperSlide>
                        <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2484.jpg" alt='КАФЕ'></img></SwiperSlide>
                        <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2482.jpg" alt='КАФЕ'></img></SwiperSlide>
                        <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2479.jpg" alt='КАФЕ'></img></SwiperSlide>
                        <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2500.jpg" alt='КАФЕ'></img></SwiperSlide>
                        <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2496.jpg" alt='КАФЕ'></img></SwiperSlide>
                        <SwiperSlide><img className="contacts__swape-image" src="/images/points/12de8edbede971d541613612cfd967f5.jpg" alt='КАФЕ'></img></SwiperSlide>
                        <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2491.jpg" alt='КАФЕ'></img></SwiperSlide>
                        <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2505.jpg" alt='КАФЕ'></img></SwiperSlide>
                        <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2512.jpg" alt='КАФЕ'></img></SwiperSlide>
                        <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2514.jpg" alt='КАФЕ'></img></SwiperSlide>
                        <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2518.jpg" alt='КАФЕ'></img></SwiperSlide>
                        <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2444.jpg" alt='КАФЕ'></img></SwiperSlide>
                        <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2446.jpg" alt='КАФЕ'></img></SwiperSlide>
                        <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2449.jpg" alt='КАФЕ'></img></SwiperSlide>
                        <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2447.jpg" alt='КАФЕ'></img></SwiperSlide>
                        <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2520.jpg" alt='КАФЕ'></img></SwiperSlide>
                        <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2521.jpg" alt='КАФЕ'></img></SwiperSlide>
                        <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2528.jpg" alt='КАФЕ'></img></SwiperSlide>
                        <SwiperSlide><img className="contacts__swape-image" src="/images/points/IMG_2531.jpg" alt='КАФЕ'></img></SwiperSlide>
                        
                        <SwiperButtonNext></SwiperButtonNext>
                        <SwiperButtonPrev></SwiperButtonPrev>

                    </Swiper>
                </div>               
            </div>
        </section>
    );
}
    
export default Corporatives;