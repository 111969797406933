import React from 'react';
import {Helmet} from "react-helmet";
import {getDashBoard, getEmployees} from '../utils/Api'
import { getCookie } from '../utils/Consts.js'

function Tracking() {

    const [ created, setCreated ] = React.useState([]);
	const [ ready, setReady ] =  React.useState([]);
	const [ inaway, setInaway ] =  React.useState([]);
    const [employees, setEmployees] = React.useState([]);

    React.useEffect(() => {
		const fetchDashBoard = () => {
			const [_created, _ready, _inaway] = [[], [], []];
			getDashBoard()
            .then((data)=>{
                data.data.forEach(order => {
                    switch (order.status) {
                        case 'created':
                            _created.push(order);
                            break;
                        case 'ready':
                            _ready.push(order);
                            break;
                        case 'inaway':
                            _inaway.push(order);
                            break;
                        default:
                            break;
                    }
                });
                setCreated(_created);
                setReady(_ready);
                setInaway(_inaway);
            })
            .catch((e)=>{
                console.log(e);
            })
			
		};

        getEmployees()
        .then((data)=>{
			setEmployees(data.data)
        })
        .catch((e)=>{
            console.log(e);
        })

		fetchDashBoard();
		const interval = setInterval(fetchDashBoard, 10000);
		return () => clearInterval(interval);
	}, []);

    return (
        <section className="tracking">

            <Helmet>
                <title>VeryFood.ru - Трекинг заказов.</title>
                <meta name="keywords" content="Статус заказа, курьеры, доставка"/>
                <meta name="description" content="Статус заказа"/>
            </Helmet>

            <div className="tracking__container">
                <h1 className="tracking__title">СТАТУС ЗАКАЗА</h1>

                <div className="tracking__orders">

                    <div className="tracking__block">
                        <p className="tracking__list-header">В СБОРКЕ</p>
                        <ul className="tracking__list">
                            {created.length
                            ?
                                created.map(a => {
                                    return <li className="tracking__list-li" key={a.id}>{a.id}{parseInt(getCookie('order_id')) === a.id ? <span className="tracking__list-li-your">ВАШ</span> : null}</li>;
                                })
                            : 
                                <div className="tracking__list-wait"><i className="fa fa-refresh" aria-hidden="true"></i> Ожидаем заказы</div>
                            }
                        </ul>
                    </div>

                    <div className="tracking__block">
                        <p className="tracking__list-header">ОЖИДАЮТ КУРЬЕРА</p>
                        <ul className="tracking__list">
                            {ready.length
                            ?
                                ready.map(a => {
                                    return <li className="tracking__list-li" key={a.id}>{a.id}{parseInt(getCookie('order_id')) === a.id ? <span className="tracking__list-li-your">ВАШ</span> : null}</li>;
                                })
                            : 
                                <div className="tracking__list-wait"><i className="fa fa-refresh" aria-hidden="true"></i> Ожидаем заказы</div>
                            }
                        </ul>
                    </div>

                    <div className="tracking__block">
                        <p className="tracking__list-header">В ДОСТАВКЕ<span className="tracking__list-header-span">Курьер</span></p>
                        <ul className="tracking__list">
                            {inaway.length
                            ?
                                inaway.map(a => {
                                    return <li className="tracking__list-li" key={a.id}>{a.id}{parseInt(getCookie('order_id')) === a.id ? <span className="tracking__list-li-your tracking__list-li-your_inaway">ВАШ</span> : null} <span className="tracking__list-li-name">{a.name}</span></li>;
                                })
                            : 
                                <div className="tracking__list-wait"><i className="fa fa-refresh" aria-hidden="true"></i> Ожидаем заказы</div>
                            }         
                        </ul>
                    </div>

                    {/* <ul className="tracking__list">
                        <li className="tracking__list-header tracking__list-header_small">МЫ ONLINE <br></br>Тел: 8 (800) 775-78-86</li>                      
                    </ul> */}
                        
                </div>

                <h2 className="tracking__title">СЕГОДНЯ ДЛЯ ВАС РАБОТАЮТ</h2>
                <div className="tracking__couriers">

                    {
                        employees.map(e => {
                            return (
                                <div className="tracking__courier">
                                    <img src={"/images/employees/" + e.picture} className="tracking__courier-photo" alt="Курьер"/>
                                    <p className="tracking__courier-fn">{e.name}</p>
                                    <p className="tracking__courier-role">Курьер</p>
                                </div>
                            );
                        })
                    }
                            
                </div>

            </div>
        </section>
    );
}
    
export default Tracking;