import reward1 from "../images/honors/g1.jpg"
import reward2 from "../images/honors/g2.jpg"
import reward3 from "../images/honors/g3.jpg"
import reward4 from "../images/honors/g4.jpg"
import {Helmet} from "react-helmet";

function About() {

    return (
        <section className="about">

            <Helmet>
                <title>VeryFood.ru - О нас.</title>
                <meta name="keywords" content="Столовые, кафе, награды, команда"/>
                <meta name="description" content="О нас"/>
            </Helmet>

            <div className="about__container">
                <h1 className="about__title">О НАС</h1>
                <p className="about__text">
                    Федеральная сеть столовых VeryFood - это демократичные столовые, расположенные в различных районах Москвы и Московской области. 
                    Мы взяли все самое лучшее из советского общепита и сделали наши блюда вкусными, разнообразными и по-настоящему домашними. 
                    Почти все, что мы предлагаем Вам, приготовлено по собственным рецептам – включая выпечку и напитки. 
                    Широкий ассортимент позволит каждому гостю подобрать блюдо по вкусу. Также мы готовим специальное меню к таким праздникам, как Новый год, Масленица, 
                    Великий пост и многим другим. И пусть Вас не пугают очереди в наших столовых! Стоять долго не придется, потому что 
                    Вы всегда можете оформить заказ на нашем сайте "Veryfood.ru" и оплатить с помощью банковской карты или наличными. 
                    Наши курьеры доставят Вам заказ в кратчайшие сроки вместе с комплектом приборов, чтобы вы могли насладиться едой без лишних неудобств. 
                    Мы вседа рады новым клиентам, приятного аппетита!
                </p>

                <h2 className="about__title">НАШИ НАГРАДЫ</h2>
                <div className="about__rewards">
                    <div className="about__reward">
                        <img className="about__reward-image" alt="Грамота" src={reward1}></img>
                    </div>
                    <div className="about__reward">
                        <img className="about__reward-image" alt="Грамота" src={reward2}></img>
                    </div>
                    <div className="about__reward">
                        <img className="about__reward-image" alt="Грамота" src={reward3}></img>
                    </div>
                    <div className="about__reward">
                        <img className="about__reward-image" alt="Грамота" src={reward4}></img>
                    </div>
                </div>

                <h2 className="about__title">НАША КОМАНДА</h2>
                <div className="about__team">
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_2636.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Сергей Олегович</p>
                            <p className="about__teammate-post about__teammate-post_red">Ген. директор</p>
                            <p className="about__teammate-role">Отличный руководитель.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_3503.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Сергей Леонидович</p>
                            <p className="about__teammate-post about__teammate-post_red">Зам. директора</p>
                            <p className="about__teammate-role">Надежная опора коллектива.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_2649.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Марина Анатольевна</p>
                            <p className="about__teammate-post about__teammate-post_red">Администратор</p>
                            <p className="about__teammate-role">Гарантирует слаженную работу коллектива.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_3454.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Мария Ивановна</p>
                            <p className="about__teammate-post about__teammate-post_green">Кассир</p>
                            <p className="about__teammate-role">На отлично справляется со сложной арифметикой.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_3468.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Даниэла Валентиновна</p>
                            <p className="about__teammate-post about__teammate-post_green">Кассир</p>
                            <p className="about__teammate-role">Всегда пожелает приятного аппетита.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_2632.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Анна Геннадьевна</p>
                            <p className="about__teammate-post about__teammate-post_green">Кассир</p>
                            <p className="about__teammate-role">В скорости счета ей нет равных.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_2641.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Алена Вадимовна</p>
                            <p className="about__teammate-post about__teammate-post_green">Кассир</p>
                            <p className="about__teammate-role">Ценит не только Ваши деньги, но и Ваше время.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_2652.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Евгения Витальевна</p>
                            <p className="about__teammate-post about__teammate-post_green">Кассир</p>
                            <p className="about__teammate-role">Знает, что такое качественное и быстрое обслуживание.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_3481.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Любовь Григорьевна</p>
                            <p className="about__teammate-post about__teammate-post_green">Кассир</p>
                            <p className="about__teammate-role">Честность в работе с клиентами - ее жизненная позиция.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_3489.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Елена Евгеньевна</p>
                            <p className="about__teammate-post about__teammate-post_green">Кассир</p>
                            <p className="about__teammate-role">Отлично понимает, что значит хорошая репутация заведения.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_3521.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Сергей Эдуардович</p>
                            <p className="about__teammate-post about__teammate-post_blue">Курьер</p>
                            <p className="about__teammate-role">Не заставит долго себя ждать.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_3525.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Степан Николаевич</p>
                            <p className="about__teammate-post about__teammate-post_blue">Курьер</p>
                            <p className="about__teammate-role">Молниеносно доставит Ваш заказ.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_2587.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Иван Евгеньевич</p>
                            <p className="about__teammate-post about__teammate-post_green">Повар</p>
                            <p className="about__teammate-role">От его блюд Ваши вкусовые рецепторы получат истинное наслаждение.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_2626.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Сергей Николаевич</p>
                            <p className="about__teammate-post about__teammate-post_green">Повар</p>
                            <p className="about__teammate-role">Попробовав раз, Вы не сможете отказать себе в удовольствии пробовать еще и еще.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_2603.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Надежда Григорьевна</p>
                            <p className="about__teammate-post about__teammate-post_green">Повар</p>
                            <p className="about__teammate-role">Ее кулинарное мастерство позволит Вам чувствовать себя как дома.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_2608.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Светлана Федоровна</p>
                            <p className="about__teammate-post about__teammate-post_green">Повар</p>
                            <p className="about__teammate-role">Свои блюда она готовит не только высокопрофессионально, но и с душой.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_3455.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Наталья Евгеньевна</p>
                            <p className="about__teammate-post about__teammate-post_green">Повар</p>
                            <p className="about__teammate-role">Верит, что человек становится значительно добрее и жизнерадостнее после вкусного обеда.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_3460.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Лев Олегович</p>
                            <p className="about__teammate-post about__teammate-post_green">Повар</p>
                            <p className="about__teammate-role">Знает как приготовить даже простые блюда поистине восхитительно.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_3445.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Василий Александрович</p>
                            <p className="about__teammate-post about__teammate-post_green">Мясник</p>
                            <p className="about__teammate-role">Кому еще можно доверить работу с мясом, как не настоящему мужчине.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_3446.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Александр Сергеевич</p>
                            <p className="about__teammate-post about__teammate-post_green">Мясник</p>
                            <p className="about__teammate-role">В совершенстве изучил все тонкости мясного дела.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_2573.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Светлана Вячеславовна</p>
                            <p className="about__teammate-post about__teammate-post_green">Холодный цех</p>
                            <p className="about__teammate-role">Голодным Вы точно не останетесь.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_2584.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Асия Мусаева</p>
                            <p className="about__teammate-post about__teammate-post_green">Холодный цех</p>
                            <p className="about__teammate-role">Для нее наивысшая похвала - видеть сытые и довольные лица клиентов.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_3500.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Сергей Евгеньевич</p>
                            <p className="about__teammate-post about__teammate-post_green">Горячий цех</p>
                            <p className="about__teammate-role">Его горячие блюда никого не оставят равнодушным.</p>
                    </div>        
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_3494.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Нина Александровна</p>
                            <p className="about__teammate-post about__teammate-post_green">Повар</p>
                            <p className="about__teammate-role">Позаботится о том, чтобы пища была не только вкусной, но и полезной.</p>
                    </div>     
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_2597.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Ольга Андреевна</p>
                            <p className="about__teammate-post about__teammate-post_blue">Раздача</p>
                            <p className="about__teammate-role">Знает толк в первоклассном обслуживании.</p>
                    </div>    
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_3458.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Татьяна Викторовна</p>
                            <p className="about__teammate-post about__teammate-post_blue">Раздача</p>
                            <p className="about__teammate-role">Для нее вежливое обращение с каждым клиентом - нерушимое правило.</p>
                    </div>    
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_3477.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Мальвина Антоновна</p>
                            <p className="about__teammate-post about__teammate-post_blue">Раздача</p>
                            <p className="about__teammate-role">Не позволит Вам долго стоять в очереди.</p>
                    </div>    
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_3485.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Кристина Михайловна</p>
                            <p className="about__teammate-post about__teammate-post_blue">Раздача</p>
                            <p className="about__teammate-role">Обслужит Вас быстро и обязательно с улыбкой.</p>
                    </div>    
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_3463.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Юля Владимировна</p>
                            <p className="about__teammate-post about__teammate-post_blue">Раздача</p>
                            <p className="about__teammate-role">Позаботится от том, чтобы Вы ушли от нас с хорошим настроением.</p>
                    </div>  
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_2594.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Галина Николаевна</p>
                            <p className="about__teammate-post about__teammate-post_yellow">Клин менеджер</p>
                            <p className="about__teammate-role">Настоящая хозяйка на своей кухне.</p>
                    </div>  
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_2592.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Ольга Вячеславовна</p>
                            <p className="about__teammate-post about__teammate-post_yellow">Клин менеджер</p>
                            <p className="about__teammate-role">Ей под силу любое загрязнение.</p>
                    </div>  
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_2611.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Елена Эдгардовна</p>
                            <p className="about__teammate-post about__teammate-post_yellow">Клин менеджер</p>
                            <p className="about__teammate-role">Сколько посуду не пачкай - будет как новая.</p>
                    </div> 
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_2617.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Алина Александровна</p>
                            <p className="about__teammate-post about__teammate-post_yellow">Клин менеджер</p>
                            <p className="about__teammate-role">От ее зоркого глаза не скроется ни одно пятнышко.</p>
                    </div> 
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_2619.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Надежда Ивановна</p>
                            <p className="about__teammate-post about__teammate-post_yellow">Клин менеджер</p>
                            <p className="about__teammate-role">После ее умелых рук посуда становится ослепительно чистой.</p>
                    </div> 
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_2624.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Марина Усмановна</p>
                            <p className="about__teammate-post about__teammate-post_yellow">Клин менеджер</p>
                            <p className="about__teammate-role">Даже наиболее требовательный клиент останется довольным ее работой.</p>
                    </div> 
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_2646.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Елена Васильевна</p>
                            <p className="about__teammate-post about__teammate-post_yellow">Клин менеджер</p>
                            <p className="about__teammate-role">Бдительно следит за тем, чтобы в заведении всегда было чисто и опрятно.</p>
                    </div> 
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_3508.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Елена Николаевна</p>
                            <p className="about__teammate-post about__teammate-post_yellow">Клин менеджер</p>
                            <p className="about__teammate-role">Отлично знает, что хороший сервис начинается с элементарного порядка.</p>
                    </div>
                    <div className="about__teammate">
                            <img src="/images/employees/IMG_3513.jpg" className="about__teammate-photo" alt="Фото"></img>
                            <p className="about__teammate-name">Раиса Абдулгисумовна</p>
                            <p className="about__teammate-post about__teammate-post_yellow">Клин менеджер</p>
                            <p className="about__teammate-role">Только качественное и ответственное исполнение работы любого уровня сложности.</p>
                    </div> 
                </div>
        
            </div>
        </section>
    );
}
    
export default About;